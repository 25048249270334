import { AfterViewInit, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SplashComponent } from './components/splash/splash.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { Platform } from '@angular/cdk/platform';
import { take, timer } from 'rxjs';
import { SwPush } from '@angular/service-worker';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, SplashComponent, HeaderComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {
    public promptEvent: any;
    public isLoading: boolean = true;
    public install: boolean = false;
    public mobileType: 'ios' | 'android' | null = null;
    public pwaSkipped: boolean = false;

    constructor(
        private platform: Platform,
        private swPush: SwPush
    ) {
        this.pwaSkipped = !!localStorage.getItem('pwa-skipped');
    }

    ngAfterViewInit() {
        this.initPwaPrompt();
    }

    loading(loading: boolean) {
        this.isLoading = loading;
    }

    public initPwaPrompt() {
        if (this.platform.IOS) {
            const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
            if (!isInStandaloneMode) {
                this.install = true;
                this.openPromptComponent('ios');
            }
        } else {
            window.addEventListener('beforeinstallprompt', (event: any) => {
                event.preventDefault();
                this.promptEvent = event;
                this.install = true;
                this.openPromptComponent('android');
            });
        }
    }

    private openPromptComponent(mobileType: 'ios' | 'android') {
        timer(3000)
            .pipe(take(1))
            .subscribe(() => {
                this.mobileType = mobileType;
            });
    }

    installPwa() {
        this.promptEvent.prompt();
    }

    requestNotificationPermission() {
        if ('Notification' in window) {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Permiso concedido para notificaciones.');
                }
            });
        }
    }

    subscribeToNotifications() {
        const VAPID_PUBLIC_KEY = 'Tu VAPID PUBLIC KEY';

        this.swPush.requestSubscription({
            serverPublicKey: VAPID_PUBLIC_KEY,
        })
            .then(sub => {
                console.log('Suscripción a notificaciones:', sub);
                // Enviar esta suscripción a tu servidor
            })
            .catch(err => console.error('Error al suscribirse:', err));
    }

    skipPwa() {
        this.install = false;
        localStorage.setItem('pwa-skipped', '1');
    }
}
