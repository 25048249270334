import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services/db.service';
import { Activitat } from '../../models/Activitat';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HeaderComponent } from '../../layout/header/header.component';
import { DatePipe, NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-single',
  standalone: true,
    imports: [
        HeaderComponent,
        NgForOf,
        NgIf,
        DatePipe,
        RouterLink
    ],
  templateUrl: './single.component.html',
  styleUrl: './single.component.scss'
})
export class SingleComponent implements OnInit{
    protected item?: Activitat;

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {

    }

    ngOnInit() {
        this._route.params.subscribe(async params => {
            this.item = await this._db.getActivitat(Number(params['id']))
        })

    }

}
