<app-header [back]="true"
            [shareData]="{title: item?.title ?? '', text: item?.descriptionShort ?? '', url: item?.url ?? ''}"></app-header>

<div class="jumbotron">

    <img *ngIf="item?.image" src="{{item?.image}}" class="card-img-top" alt="Event Image">
    <img *ngIf="!item?.image" src="/img/placeholder/activitat.jpg" class="card-img-top" alt="Event Image">
    <div class="over-text">
        <div class="container-fluid">
            <h1>{{ item?.title }}</h1>
            <div class="event-details">
                <p>{{ item?.start|date:'dd/MM/yyyy' }} a les {{ item?.start|date:'HH:mm' }}h</p>
                <p *ngIf="item?.ubicacio">
                    <a class="text-reset" [routerLink]="['/ubicacio', item?.ubicacio?.id]">{{ item?.ubicacio?.nom }} <i class="fa fa-location-dot"></i></a>
                </p>
                <p>
                    <span *ngFor="let organitzador of item?.organitzadors; let i = index;"><span
                        *ngIf="i > 0">, </span>{{ organitzador.nom }}</span>
                </p>
            </div>
            <div class="event-categories mt-3">
                <span class="badge"
                      *ngFor="let category of item?.categories"
                      [routerLink]="['/categoria', category?.id]">{{ category.nom }}</span>
            </div>
            <div class="scroll-down">
                <i class="fa-solid fa-angle-down"></i>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">

    <!-- Contenido del evento -->
    <div class="event-body container">
        <h5 class="mb-3" [innerHTML]="item?.descriptionShort"></h5>
        <p [innerHTML]="item?.description"></p>
        <span *ngIf="item?.schedule"><strong>Horari:</strong> {{ item?.schedule }}</span><br>
        <span *ngIf="item?.price"><strong>Preu:</strong> {{ item?.price }}</span>
    </div>
</div>
