import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../layout/header/header.component';
import { Category } from '../../models/Category';
import { DbService } from '../../services/db.service';
import { CommonModule, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-profile',
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        NgForOf,
        FormsModule
    ],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {

    public categories: Category[] = [];

    constructor(
        private _db: DbService
    ) {
    }

    async ngOnInit() {
        this.categories = await this._db.getAllCategories();
        this.categories.sort((a, b) => a.nom.toUpperCase() > b.nom.toUpperCase() ? 1 : -1);
    }

    async check(category: Category) {
        try {
            category.checked = !category.checked;
            await this._db.addCategoria(category);
            const activitats = await this._db.getAllActivitats();

            for (const activitat of activitats) {
                for (const c of activitat.categories) {
                    if(c.id === category.id) {
                        c.checked = category.checked;
                    }
                }
                await this._db.addActivitat(activitat);
            }
        }catch (e: any) {
            console.log(e);
        }
    }

    async selectAll(all: boolean) {
        for (let category of this.categories) {
            try {
                if(category.checked === all)
                    continue;
                category.checked = all;
                await this._db.addCategoria(category);
                const activitats = await this._db.getAllActivitats();

                for (const activitat of activitats) {
                    for (const c of activitat.categories) {
                        if (c.id === category.id) {
                            c.checked = category.checked;
                        }
                    }
                    await this._db.addActivitat(activitat);
                }
            } catch (e: any) {
                console.log(e);
            }
        }
    }
}
