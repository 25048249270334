import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivitatService } from '../../services/activitat.service';
import { DbService } from '../../services/db.service';
import { lastValueFrom } from 'rxjs';
import { Json } from '../../models/Json';

@Component({
    selector: 'app-splash',
    standalone: true,
    imports: [],
    templateUrl: './splash.component.html',
    styleUrl: './splash.component.scss',
})
export class SplashComponent implements OnInit, OnChanges {
    @Input() load: boolean = false;
    @Output() onLoad = new EventEmitter<unknown>();
    private loading: boolean = false;

    constructor(
        private _activitats: ActivitatService,
        private _db: DbService
    ) {
    }

    ngOnInit() {
        setInterval(async () => {
            if (!this.loading) {
                await this.sync();
            }
        }, 10000);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes['load']) {
            if (this.load) {
                await this.sync();
            }
        }
    }

    private async sync() {
        this.loading = true;
        try {
            // Fecha de última actualziación
            const lastUpdateDate: string = await lastValueFrom(this._activitats.getInfo());


            const currentDateString: string | null = localStorage.getItem('lastUpdateDate002');


            const updatedOn = new Date(lastUpdateDate); // Fecha a comparar
            const currentDate = currentDateString ? new Date(currentDateString) : null; // Fecha actual
            if (!currentDate || updatedOn > currentDate) {
                // JSON de todas las actividades
                const data: Json = await lastValueFrom(this._activitats.getEvents());
                await this._db.syncActivitats(data['Agenda cultural de Sabadell'].Activitat);
                localStorage.setItem('lastUpdateDate002', lastUpdateDate);
            }
        } catch (e: any) {
            // En caso de error cargamos igual la aplicación ya que debe de poder funcionar offline
        }
        this.onLoad.emit();
        this.loading = false;
    }
}
