import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../layout/header/header.component';
import { ActivitatsComponent } from '../activitats/activitats.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Activitat } from '../../models/Activitat';
import { DbService } from '../../services/db.service';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../../models/Category';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Search } from '../../models/Search';

@Component({
  selector: 'app-search',
  standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        ActivitatsComponent,
        InfiniteScrollDirective,
        FormsModule
    ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit {
    protected activitats: Activitat[] = [];
    public start: number = 0;
    public end: number = 20;
    public limit: number = 20;
    public categories: Category[] = [];
    public categoryOpened: boolean = false;
    public search: Search;

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {
        let localSearch: Search|string|null = localStorage.getItem('search');

        if(!localSearch)
            this.search = new class implements Search {
                categories: Category[] =  [];
            };
        else
            this.search = JSON.parse(localSearch);
    }

    async ngOnInit() {

        this.categories = await this._db.getAllCategories();
        this.categories.sort((a, b) => a.nom.toUpperCase() > b.nom.toUpperCase() ? 1 : -1);

        await this.refresh();
    }

    public async refresh() {
        try {
            const activities = await this._db.getAllActivitats(this.start, this.end);
            for (const activitat of activities) {
                for (const category of this.categories) {
                    if(!category.checked)
                        continue;
                    const checkedCategory = activitat.categories.findIndex(c => c.nom === category.nom) !== -1;
                    if(checkedCategory) {
                        const exist = this.activitats.findIndex(a => a.id === activitat.id) !== -1;
                        if(!exist)
                            this.activitats.push(activitat);
                    }
                }

            }

            if(this.activitats.length < 3 && this.start < 100) {
                this.start = this.activitats.length - 1;
                this.end = this.activitats.length + this.limit;
                await this.refresh();
            }

        } catch (error) {
            console.error('Error fetching categories or activities:', error);
        }
    }

    async onScrollDown() {
        this.start = this.activitats.length - 1;
        this.end = this.activitats.length + this.limit;
        await this.refresh();
    }

    onScrollUp() {
        console.log("scrolled up!!");
    }
    async check(category: Category) {
        try {
            category.checked = !category.checked;
            this.activitats = [];
            this.start = 0;
            this.end = this.activitats.length + this.limit;
            this.searchChange();
            await this.refresh();

        }catch (e: any) {
            console.log(e);
        }
    }
    async selectAll(all: boolean) {
        for (let category of this.categories) {
            try {
                if(category.checked === all)
                    continue;
                category.checked = all;
            } catch (e: any) {
                console.log(e);
            }
        }
        this.activitats = [];
        this.start = 0;
        this.end = this.activitats.length + this.limit;

        await this.refresh();
    }

    searchChange() {
        localStorage.setItem('search', JSON.stringify(this.search));
    }
}
