import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { DbService } from '../../services/db.service';
import { HeaderComponent } from '../../layout/header/header.component';
import { Activitat } from '../../models/Activitat';
import { CommonModule } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ActivitatComponent } from '../../components/activitat/activitat.component';
import { ActivitatsComponent } from '../activitats/activitats.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [CommonModule, HeaderComponent, InfiniteScrollDirective, ActivitatComponent, ActivitatsComponent, RouterLink, CarouselModule],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements AfterContentInit {

    public activitats: Activitat[] = [];
    public start: number = 0;
    public end: number = 20;
    public limit: number = 20;
    public all: boolean = false;

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {
    }

    async ngAfterContentInit() {
        this.activitats = [];
        await this.refresh();
    }

    public async refresh() {
        try {
            this._route.url.subscribe(async next => {
                if (next.pop()?.path === 'all') {
                    this.all = true;
                    const activities = await this._db.getAllActivitats(this.start, this.end);
                    for (const activitat of activities) {
                        const exist = this.activitats.findIndex(a => a.id === activitat.id) !== -1;
                        if(!exist)
                            this.activitats.push(activitat);
                    }
                    this.activitats = this.activitats.concat();
                } else {
                    const activities = await this._db.getAllActivitats(this.start, this.end);
                    for (const activitat of activities) {
                        const checkedCategory = activitat.categories.findIndex(c => c.checked) !== -1;
                        if(checkedCategory) {
                            const exist = this.activitats.findIndex(a => a.id === activitat.id) !== -1;
                            if(!exist)
                                this.activitats.push(activitat);
                        }
                    }
                }
            })
        } catch (error) {
            console.error('Error fetching categories or activities:', error);
        }
    }

    async onScrollDown() {
        this.start = this.activitats.length - 1;
        this.end = this.activitats.length + this.limit;
        await this.refresh();
    }

    onScrollUp() {
        console.log("scrolled up!!");
    }
}
