<app-splash *ngIf="isLoading" (onLoad)="loading(false)" [load]="isLoading"></app-splash>
<!-- Android prompt -->
<nav *ngIf="!pwaSkipped && install && mobileType === 'android'" class="navbar navbar-expand-lg navbar-light bg-primary android-prompt">
    <div class="container-fluid">
        <button class="btn btn-primary close-ios-inv" (click)="skipPwa()">
            <i class="fas fa-times"></i>
        </button>
        <button class="btn btn-primary w-100" (click)="installPwa()">
            <b>Instal·lar la aplicació</b>
            <img width="30px" class="ms-4" src="/icons/icon-72x72.png" alt="">
        </button>
    </div>
</nav>

<!-- iOS prompt -->
<nav *ngIf="!pwaSkipped && install && mobileType === 'ios'" class="navbar navbar-expand-lg navbar-light bg-white android-prompt">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <i class="fas fa-times" (click)="skipPwa()"></i>
                <div class="py-2 px-4">
                    <h4>Instal·lar la aplicació</h4>
                    1. Premi <img src="/assets/ios-menu-btn.png" alt="Menu button"><br>
                    2.  Sel·lecciona <b>"Afegir a la pantalla d'inici"</b>
                </div>
            </div>
        </div>
    </div>
</nav>
<router-outlet *ngIf="!isLoading"></router-outlet>


